import React from 'react';

interface GenerateButtonProps {
  onGenerate: () => void;
}

const GenerateButton: React.FC<GenerateButtonProps> = ({ onGenerate }) => {
  return (
    <button
      type="button"
      onClick={onGenerate}
      className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
    >
      Generate Slideshow
    </button>
  );
};

export default GenerateButton;
