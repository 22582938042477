import React from 'react';

interface DownloadLinkProps {
  videoUrl: string;
  isRendering: boolean;
}

const DownloadLink: React.FC<DownloadLinkProps> = ({ videoUrl, isRendering }) => {
  if (isRendering) {
    return (
      <button
        type="button"
        className="rounded-md mx-4 bg-indigo-600 h-8 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm cursor-not-allowed inline-flex items-center"
        disabled
      >
        <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-purple-500 mr-2"></div>
        Rendering Video...
      </button>
    );
  }
  return videoUrl ? (
    <a
      href={`${videoUrl}`}
      download="slideshow.mp4"
      className="rounded-md mx-4 bg-indigo-600 h-8 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
    >
      Download Video
    </a>
  ) : null;
};

export default DownloadLink;