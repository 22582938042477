import React, { useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import { Button, Fieldset, Input, Label, Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import clsx from 'clsx';

const ServiceSheet: React.FC = () => {
  const tabRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];

  const [content, setContent] = useState<Record<string, any>[]>([
    { title: 'Celebrating the Life of', name: 'John Doe', text: "A Life Well-Lived", dob: "01/01/1950", dod: "01/01/2022", location: "St. Mary's Church" },
    { title: 'Funeral Service Sheet - Page 2', text: 'This is the content of the second page.' },
    { title: 'Funeral Service Sheet - Page 3', text: 'This is the content of the third page.' },
    { title: 'Funeral Service Sheet - Page 4', text: 'This is the content of the fourth page.' },
  ]);

  const handleInputChange = (index: number, field: string, value: string) => {
    const newContent = [...content];
    newContent[index][field] = value;
    setContent(newContent);
  };

  const takeScreenshot = async () => {
    const images = [];
    for (let ref of tabRefs) {
      if (ref.current) {
        const canvas = await html2canvas(ref.current);
        const imgData = canvas.toDataURL('image/png');
        images.push(imgData);
      }
    }
    sendScreenshotToBackend(images);
  };

  const handleImageUpload = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const newContent = [...content];
        newContent[index].photo = reader.result as string;
        setContent(newContent);
      };
      reader.readAsDataURL(file);
    }
  };

  const sendScreenshotToBackend = async (images: string[]) => {
    try {
      const response = await fetch('/generate-service-sheet', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ images }),
      });
      const result = await response.json();
      window.open(result.fileUrl, '_blank');
    } catch (error) {
      console.error('Error sending screenshots:', error);
    }
  };

  return (
    <div>
      <TabGroup>
        <TabList className="flex space-x-1">
          <Tab className="data-[selected]:bg-blue-500 w-full data-[selected]:text-white py-2.5 text-sm font-medium data-[hover]:underline">Page 1</Tab>
          <Tab className="data-[selected]:bg-blue-500 w-full data-[selected]:text-white py-2.5 text-sm font-medium data-[hover]:underline">Page 2</Tab>
          <Tab className="data-[selected]:bg-blue-500 w-full data-[selected]:text-white py-2.5 text-sm font-medium data-[hover]:underline">Page 3</Tab>
          <Tab className="data-[selected]:bg-blue-500 w-full data-[selected]:text-white py-2.5 text-sm font-medium data-[hover]:underline">Page 4</Tab>
        </TabList>
        <TabPanels>
          <TabPanel className="flex">
            <div
              style={{ width: '50%', }}
            >
              <div
                ref={tabRefs[0]}
                style={{ height: '595px', width: '420px', border: '1px solid black' }}
                className="py-8"
              >
                <div className="text-center space-y-4">
                  <h1 className="text-3xl font-opensans">{content[0].title}</h1>
                  <h2 className="text-6xl pt-4 font-greatvibes">{content[0].name}</h2>
                  {content[0].photo && (
                    <div className="flex justify-center">
                      <img
                        src={content[0].photo}
                        className="w-48 h-48 rounded-full object-cover my-8"
                      />
                    </div>
                  )}
                  <p className="italic text-xl font-opensansfont-opensans">{content[0].text}</p>
                  <p className="italic text-lg font-opensans">
                    {content[0].dob} - {content[0].dod}
                  </p>
                </div>
              </div>
            </div>
            <div style={{ width: '50%', padding: '10px' }}>
              <Fieldset className="space-y-2 rounded-xl bg-white/5 p-6 sm:p-10">
                <div>
                  <Label className="text-sm/6 font-medium text-black">Title</Label>
                  <Input
                    value={content[0].title}
                    onChange={(e) => handleInputChange(0, 'title', e.target.value)}
                    className={clsx(
                      'block w-full rounded-lg border-none bg-black/5 py-1.5 px-3 text-sm/6 text-black',
                      'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                    )}
                  />
                </div>
                <div>
                  <Label className="text-sm/6 font-medium text-black">Name</Label>
                  <Input
                    value={content[0].name}
                    onChange={(e) => handleInputChange(0, 'name', e.target.value)}
                    className={clsx(
                      'block w-full rounded-lg border-none bg-black/5 py-1.5 px-3 text-sm/6 text-black',
                      'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                    )}
                  />
                </div>
                <div>
                  <Label className="text-sm/6 font-medium text-black">Text</Label>
                  <Input
                    value={content[0].text}
                    onChange={(e) => handleInputChange(0, 'text', e.target.value)}
                    className={clsx(
                      'block w-full rounded-lg border-none bg-black/5 py-1.5 px-3 text-sm/6 text-black',
                      'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                    )}
                  />
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Label className="text-sm/6 font-medium text-black">Date of Birth</Label>
                    <Input
                      value={content[0].dob}
                      onChange={(e) => handleInputChange(0, 'dob', e.target.value)}
                      className={clsx(
                        'block w-full rounded-lg border-none bg-black/5 py-1.5 px-3 text-sm/6 text-black',
                        'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                      )}
                    />
                  </div>
                  <div>
                    <Label className="text-sm/6 font-medium text-black">Date of Death</Label>
                    <Input
                      value={content[0].dod}
                      onChange={(e) => handleInputChange(0, 'dod', e.target.value)}
                      className={clsx(
                        'block w-full rounded-lg border-none bg-black/5 py-1.5 px-3 text-sm/6 text-black',
                        'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                      )}
                    />
                  </div>
                </div>
                <div>
                  <Label className="text-sm/6 font-medium text-black">Upload Photo</Label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageUpload(0, e)}
                    className={clsx(
                      'block w-full rounded-lg border-none bg-black/5 py-1.5 px-3 text-sm/6 text-black',
                      'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                    )}
                  />
                </div>

              </Fieldset>


            </div>
          </TabPanel>
          <TabPanel className="flex">
            <div
              ref={tabRefs[1]}
              style={{ width: '50%', height: '595px', border: '1px solid black' }}
            >
            </div>
            <div style={{ width: '50%', padding: '10px' }}>

            </div>
          </TabPanel>
          <TabPanel className="flex">
            <div
              ref={tabRefs[2]}
              style={{ width: '50%', height: '595px', border: '1px solid black' }}
            >
            </div>
            <div style={{ width: '50%', padding: '10px' }}>

            </div>
          </TabPanel>
          <TabPanel className="flex">
            <div
              ref={tabRefs[3]}
              style={{ width: '50%', height: '595px', border: '1px solid black' }}
            >
            </div>
            <div style={{ width: '50%', padding: '10px' }}>

            </div>
          </TabPanel>
        </TabPanels>
      </TabGroup>
      <div className="flex justify-center mt-4">
        <Button
          className="my-4 rounded bg-indigo-600 px-4 py-2 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mx-auto"
          onClick={takeScreenshot}>Generate PDF</Button>
      </div>
    </div>
  );
};

export default ServiceSheet;
