import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import VideoSlideshow from './VideoSlideshow';
import ServiceSheet from './ServiceSheet';
import NavBar from './components/NavBar';

const App: React.FC = () => {
  return (
    <Router>
      <NavBar />
      <div className="container mx-auto p-4">
        <Routes>
          <Route path="/" element={<Navigate to="/video-slideshow" />} />
          <Route path="/video-slideshow" element={<VideoSlideshow />} />
          <Route path="/service-sheet" element={<ServiceSheet />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
