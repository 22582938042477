// src/VideoSlideshow.tsx
import React, { useState } from 'react';
import FileUpload from './components/FileUpload';
import ImageGrid from './components/ImageGrid';
import MusicDropdown from './components/MusicDropdown';
import GenerateButton from './components/GenerateButton';
import DownloadLink from './components/DownloadLink';

const predefinedTracks = ['amazing-grace.mp3', 'funeral-background.mp3'];

const VideoSlideshow: React.FC = () => {
  const [images, setImages] = useState<{ name: string, file: File, data: string, id: string }[]>([]);
  const [selectedTrack, setSelectedTrack] = useState<string>('amazing-grace.mp3');
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRendering, setIsRendering] = useState<boolean>(false);

  const handleGenerate = async () => {
    setVideoUrl('');
    try {
      setIsLoading(true);
      const createResponse = await fetch('/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ numFiles: images.length }),
      });
      const { folderId, uploadUrls } = await createResponse.json();
      // Upload images to S3
      await Promise.all(images.map((image, index) => fetch(uploadUrls[index], {
        method: 'PUT',
        headers: {
          'Content-Type': 'image/jpeg',
        },
        body: image.file,
      })));
      console.log('Images uploaded successfully');
      const response = await fetch('/generate-slideshow', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ folderId, selectedTrack }),
      });
      setIsRendering(true);
      const data = await response.json();
      console.log(process.env.REACT_APP_WS_URL)
      const ws = new WebSocket(`${process.env.REACT_APP_WS_URL}`);
      ws.onopen = () => {
        ws.send(JSON.stringify({ jobId: data.id }));
      };
      ws.onmessage = (event) => {
        const { status, url } = JSON.parse(event.data);
        if (status === 'done') {
          ws.close();
          setIsRendering(false);
          setVideoUrl(url);
        }
      };
      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    } catch (error) {
      console.error('Error generating video', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <FileUpload onFilesExtracted={setImages} />
      <ImageGrid images={images} setImages={setImages} />
      <MusicDropdown tracks={predefinedTracks} selectedTrack={selectedTrack} setSelectedTrack={setSelectedTrack} />
      {isLoading ? (
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-purple-500"></div>
        </div>
      ) : (
        <div className='flex'>
          <GenerateButton onGenerate={handleGenerate} />
          <DownloadLink videoUrl={videoUrl} isRendering={isRendering} />
        </div>
      )}
    </div>
  );
};

export default VideoSlideshow;