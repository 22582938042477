import React from 'react';
import { FolderIcon } from '@heroicons/react/24/solid';

interface FileUploadProps {
  onFilesExtracted: (images: { name: string, file: File, id: string, data: string }[]) => void;
}

const FileUpload: React.FC<FileUploadProps> = ({ onFilesExtracted }) => {
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const images: { name: string, file: File, id: string, data: string }[] = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const data = await new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = event => resolve(event.target?.result as string);
          reader.onerror = error => reject(error);
          reader.readAsDataURL(file);
        });
        images.push({ name: file.name, file, id: file.name, data: data});
      }
      onFilesExtracted(images);
    }
  };


  return (
    <div className='my-4'>
      <div className="col-span-full">
        <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
          Upload images
        </label>
        <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
          <div className="text-center">
            <FolderIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
            <div className="mt-4 flex text-sm leading-6 text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
              >
                <span>Upload a file</span>
                <input multiple id="file-upload" name="file-upload" type="file" accept="image/*" onChange={handleFileChange} className="sr-only" />
              </label>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
